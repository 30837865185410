<script>
export default {
    data() {
        return {
            lang: {
                iso: this.$store.state.lang,
                translation: this.$translation,
            },
            premium: 0,
            menu: {
                state: 0,
            },
            assets: {
                fish: require("@/assets/image/fish.svg"),
                shield: require("@/assets/image/shield.svg"),
            },
            subscribe: null,
        };
    },
    methods: {
        Translate(name) {
            var ctx = this;
            if (ctx.lang.translation[ctx.lang.iso][name])
                return ctx.lang.translation[ctx.lang.iso][name];
            else return "None";
        },
    },
    computed: {
		info() {
			return this.$store.state.info;
		},
	},
	mounted() {
		var ctx = this;

		ctx.subscribe = ctx.$store.subscribe((mutation) => {
			if (mutation.type == "info" && mutation.payload.status >= 1) {
				// ctx.Refresh();
			}

            if (mutation.type == "lang") {
				ctx.lang.iso = mutation.payload;
			}
		});
	},
	beforeUnmount() {
		this.subscribe();
	},
};
</script>

<style>
@import "../../assets/scss/sidebar/bot.css";
</style>

<template>
    <section class="bot-sidebar">
        <div
            class="sidebar"
            @mouseover="menu.state = 1"
            @mouseleave="menu.state = 0"
            :class="{
                opacity: menu.state,
            }"
            v-if="menu.state == 1 && info.status >= 1"
        >
            <div class="title">{{Translate("title_bot")}}</div>
            <div class="item"></div>
        </div>

        <div
            class="sidebar"
            @mouseover="menu.state = 1"
            @mouseleave="menu.state = 0"
            v-if="menu.state == 1 && info.status == 0"
            :class="{
                opacity: menu.state,
            }"
        >
            <div class="premium">
                <div class="title">{{Translate("title_bot")}}</div>
                <div class="placement">
                    <div class="top">
                        <img :src="assets.fish" />
                    </div>
                    <div class="text">
                        {{Translate("premium_text")}}
                    </div>
                    <div class="shop-button">
                        <router-link class="go-to-shop" to="/shop">{{
                            Translate("acces_shop")
                        }}</router-link>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="switch-mode"
            v-if="menu.state == 0"
            @mouseover="menu.state = 1"
        >
            {{Translate("name_bot_sidebar")}}
        </div>
    </section>
</template>