<script>
import randomstring from "randomstring";

export default {
    data() {
        return {
            toast: {},
        };
    },
    methods: {
        add_toast(title, content, time) {
            var ctx = this;
            var tag = randomstring.generate();

            ctx.toast[tag] = {
                title: title,
                content: content,
                time: time,
                exit: 0,
            };

            setTimeout(function () {
                ctx.toast[tag].exit = 1;
                setTimeout(function () {
                    delete ctx.toast[tag];
                }, 500);
            }, time * 1000);
        },
        close(index) {
            var ctx = this;
            ctx.toast[index].exit = 1;
            setTimeout(function () {
                delete ctx.toast[index];
            }, 500);
        },
    },
    mounted() {
        var ctx = this;

        ctx.$bus.$on("toast", function (data) {
            ctx.add_toast(data.title, data.content, data.time)
        });
    },
};
</script>

<style>
@import url("../assets/scss/toast/toast.css");
</style>

<template>
    <div class="toast">
        <div class="center">
            <div class="list">
                <div
                    v-for="(value, index) in toast"
                    :key="index"
                    :class="{
                        notification: 1,
                        opacity: 1,
                        'slide-out-top': value.exit,
                    }"
                >
                    <div class="title">{{ value.title }}</div>
                    <div class="content">
                        {{ value.content }}
                    </div>
                    <div class="close" v-on:click="close(index)">
                        <i class="fas fa-times"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>