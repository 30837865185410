<script>
export default {
    data() {
        return {
            lang: {
                iso: this.$store.state.lang,
                translation: this.$translation,
            },
            animation_exit: 0,
            country: [
                {
                    name: "Français",
                    iso: "fr",
                },
                {
                    name: "English",
                    iso: "gb",
                },
                {
                    name: "Español",
                    iso: "es",
                },
                {
                    name: "Русский",
                    iso: "ru",
                },
            ],
            subscribe: null,
        };
    },
    props: ["state"],
    methods: {
        close() {
            var ctx = this;
            ctx.animation_exit = 1;
            setTimeout(function () {
                ctx.$emit("settings_state", 0);
                ctx.animation_exit = 0;
            }, 500);
        },
        setCookie(name, value, days) {
            var expires = "";
            if (days) {
                var date = new Date();
                date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
                expires = "; expires=" + date.toUTCString();
            }
            document.cookie = name + "=" + (value || "") + expires + "; path=/";
        },
        country_choose(iso) {
            var ctx = this;
            ctx.setCookie("lang", iso, 365);
            ctx.$store.commit("lang", iso);

        },
        Translate(name) {
            var ctx = this;
            if (ctx.lang.translation[ctx.lang.iso][name])
                return ctx.lang.translation[ctx.lang.iso][name];
            else return "None";
        },
    },
	mounted() {
		var ctx = this;

		ctx.subscribe = ctx.$store.subscribe((mutation) => {
			if (mutation.type == "lang") {
				ctx.lang.iso = mutation.payload;
			}
		});
	},
	beforeUnmount() {
		this.subscribe();
	},
};
</script>

<style>
@import url("../../assets/scss/navbar/settings.css");
</style>

<template>
    <div class="modal-settings opacity" v-if="state === 1">
        <div class="center">
            <div :class="{ 'slide-out-top': animation_exit }">
                <div class="title">
                    {{ Translate("settings_title") }}
                </div>
                <div class="sub-title">{{ Translate("lang_title") }} :</div>
                <div class="lang">
                    <div
                        :class="{ country: 1, active: value.iso === lang.iso }"
                        v-for="(value, index) in country"
                        :key="index"
                        v-on:click="country_choose(value.iso)"
                    >
                        <img
                            :src="
                                'https://www.countryflags.io/' +
                                value.iso +
                                '/flat/64.png'
                            "
                        />
                        <span>{{ value.name }}</span>
                    </div>
                </div>
                <div class="close">
                    <a href="javascript:void(1)" v-on:click="close">{{
                        Translate("close")
                    }}</a>
                </div>
            </div>
        </div>
    </div>
</template>