<script>
export default {
    data() {
        return {
            lang: {
                iso: this.$store.state.lang,
                translation: this.$translation,
            },
            emote: "",
            emote_list: {
                citizen: require("@/assets/image/citizen.gif"),
                jew: require("@/assets/image/jew.png"),
                oh: require("@/assets/image/oh.png"),
                confused_n: require("@/assets/image/confused_n.png"),
                stonk: require("@/assets/image/stonk.png"),
                cry: require("@/assets/image/cry.gif"),
                staline: require("@/assets/image/staline.png"),
                speed: require("@/assets/image/speed.gif"),
            },
            animation_exit: 0,
        };
    },
    methods: {
        close() {
            var ctx = this;

            ctx.animation_exit = 1;
            ctx.emote = "";

            setTimeout(function () {
                ctx.$emit("add_credit", "1");
                ctx.animation_exit = 0;
            }, 500);
        },
        Translate(name) {
            var ctx = this;
            if (ctx.lang.translation[ctx.lang.iso][name])
                return ctx.lang.translation[ctx.lang.iso][name];
            else return "None";
        },
    },
    props: ["state"],
    mounted() {
		var ctx = this;

		ctx.subscribe = ctx.$store.subscribe((mutation) => {
            if (mutation.type == "lang") {
				ctx.lang.iso = mutation.payload;
			}
		});
	},
	beforeUnmount() {
		this.subscribe();
	},
};
</script>

<style>
@import url("../../assets/scss/navbar/add-credit.css");
</style>

<template>
    <div class="modal-credit opacity" v-if="state === 1" v-on:click="close()">
        <div class="center">
            <div :class="{ list: 1, 'slide-out-top': animation_exit }">
                <div class="title">{{ Translate("buy_credit_title") }}</div>
                <div
                    class="item"
                    data-sellix-product="6086f73026b10"
                    @mouseover="emote = 'jew'"
                    @mouseleave="emote = ''"
                >
                    {{ Translate("add_credit_0") }} <span>10€</span> {{ Translate("add_credit_1") }}
                </div>
                <div
                    class="item"
                    @mouseover="emote = 'staline'"
                    @mouseleave="emote = ''"
                    data-sellix-product="6086f73026b10"
                >
                    {{ Translate("add_credit_0") }} <span>15€</span> {{ Translate("add_credit_1") }}
                </div>
                <div
                    class="item"
                    @mouseover="emote = 'oh'"
                    @mouseleave="emote = ''"
                    data-sellix-product="6086f73026b10"
                >
                    {{ Translate("add_credit_0") }} <span>20€</span> {{ Translate("add_credit_1") }}
                </div>
                <div
                    class="item"
                    @mouseover="emote = 'stonk'"
                    @mouseleave="emote = ''"
                    data-sellix-product="6086f73026b10"
                >
                    {{ Translate("add_credit_0") }} <span>30€</span> {{ Translate("add_credit_1") }}
                </div>
                <div
                    class="item"
                    @mouseover="emote = 'speed'"
                    @mouseleave="emote = ''"
                    data-sellix-product="6086f73026b10"
                >
                    {{ Translate("add_credit_0") }} <span>40€</span> {{ Translate("add_credit_1") }}
                </div>
                <div
                    class="item"
                    @mouseover="emote = 'citizen'"
                    @mouseleave="emote = ''"
                    data-sellix-product="6086f73026b10"
                >
                    {{ Translate("add_credit_0") }} <span>50€</span> {{ Translate("add_credit_1") }}
                </div>
                <div
                    class="close"
                    @mouseover="emote = 'cry'"
                    @mouseleave="emote = ''"
                    v-on:click="close()"
                >
                    {{ Translate("deny_request") }}
                </div>
                <div class="active" v-if="emote_list[emote]">
                    <img :src="emote_list[emote]" width="100" />
                </div>
            </div>
        </div>
    </div>
</template>