<script>
export default {
	data() {
		return {
			add_menu: 0,
			invite_code: null,
			lang: {
                iso: this.$store.state.lang,
                translation: this.$translation,
			},
			servers_list: {
				state: 0,
			},
			servers: {},
			assets: {
				fish: require("@/assets/image/fish.svg"),
				shield: require("@/assets/image/shield.svg"),
				error: require("@/assets/image/avatar_discord.png"),
			},
			subscribe: null,
		};
	},
	methods: {
		Translate(name) {
			var ctx = this;
			if (ctx.lang.translation[ctx.lang.iso][name])
				return ctx.lang.translation[ctx.lang.iso][name];
			else return "None";
		},
		async Refresh() {
			var ctx = this;
			var refresh_list = await ctx.axios.get(
				ctx.end_point + "/servers/list",
				{
					headers: {
						token: localStorage.getItem("token"),
					},
				}
			);

			ctx.servers = {};

			for (let index = 0; index < refresh_list.data.length; index++) {
				var data = JSON.parse(refresh_list.data[index].data);
				ctx.servers[index] = {
					id: data.guild.id,
					name: data.guild.name,
					icon: data.guild.icon,
					total: data.approximate_member_count,
				};
			}
		},
		async AddServer() {
			var ctx = this;
			var add_info = await ctx.axios.post(
				ctx.end_point + "/servers/add",
				{
					code: ctx.invite_code,
				},
				{
					headers: {
						token: localStorage.getItem("token"),
					},
				}
			);

			var status = add_info.data.status;

			if (status == "used") {
				ctx.$bus.$emit("toast", {
					title: "Error",
					content: "This server has already been added",
					time: 5,
				});
				return;
			}
			if (status == "blacklist") {
				ctx.$bus.$emit("toast", {
					title: "Blacklist",
					content: "This server is protected by our team.",
					time: 5,
				});
				return;
			}
			if (status == "wrong") {
				ctx.$bus.$emit("toast", {
					title: "Error",
					content: "This invitation is wrong.",
					time: 5,
				});
				return;
			}
			if (status == "added") {
				ctx.$bus.$emit("toast", {
					title: "Success",
					content: "You have added a new server.",
					time: 5,
				});
				ctx.Refresh();
				return;
			}
		},
	},
	computed: {
		info() {
			return this.$store.state.info;
		},
	},
	mounted() {
		var ctx = this;

		ctx.subscribe = ctx.$store.subscribe((mutation) => {
			if (mutation.type == "info" && mutation.payload.status >= 1) {
				ctx.Refresh();
			}
			
			if (mutation.type == "lang") {
				ctx.lang.iso = mutation.payload;
			}
		});
	},
	beforeUnmount() {
		this.subscribe();
	},
};
</script>

<style>
@import "../../assets/scss/sidebar/servers.css";
</style>

<template>
	<section
		:class="{
			'servers-list': 1,
		}"
		@mouseover="servers_list.state = 1"
		@mouseleave="servers_list.state = 0"
	>
		<div
			class="sidebar"
			v-if="servers_list.state == 1 && info.status >= 1"
			:class="{ opacity: servers_list.state == 1 }"
		>
			<div class="title">{{ Translate("title_servers") }}</div>
			<div
				class="item-add"
				v-if="add_menu == 0"
				v-on:click="add_menu = 1"
			>
				<a href="#">{{ Translate("add_servers") }}</a>
			</div>
			<div
				class="item-add-close"
				v-if="add_menu == 1"
				v-on:click="add_menu = 0"
			>
				<a href="#">{{ Translate("close") }}</a>
			</div>
			<div class="add-servers opacity" v-if="add_menu">
				<input
					type="text"
					v-model="invite_code"
					:placeholder="'discord.gg/code'"
					maxlength="40"
				/>
			</div>
			<div class="item-add-valid" v-if="add_menu" v-on:click="AddServer">
				<a href="#">{{ Translate("validate") }}</a>
			</div>
			<div class="list">
				<div
					class="item"
					v-for="(value, index) in servers"
					:key="index"
					v-on:click="
						$router.push({
							name: 'servers',
							params: { id: value.id },
						})
					"
				>
					<div class="icone">
						<img
							v-if="value.icon"
							:src="
								end_point +
								'/assets/icon/' +
								value.icon +
								'.jpg'
							"
							@error="servers[index].icon = null"
						/>
						<img v-if="!value.icon" :src="assets.error" />
					</div>
					<div class="name" :title="value.name">{{ value.name }}</div>
					<div class="info">
						<div class="total">
							<i class="fas fa-users"></i> {{ value.total }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			class="sidebar"
			v-if="servers_list.state == 1 && info.status == 0"
			:class="{ opacity: servers_list.state == 1 }"
			@mouseover="servers_list.state = 1"
			@mouseleave="servers_list.state = 0"
		>
			<div class="premium">
				<div class="title">{{ Translate("title_servers") }}</div>
				<div class="placement">
					<div class="top">
						<img :src="assets.fish" />
					</div>
					<div class="text">
						{{ Translate("premium_text") }}
					</div>
					<div class="shop-button">
						<router-link class="go-to-shop" to="/shop">{{
							Translate("acces_shop")
						}}</router-link>
					</div>
				</div>
			</div>
		</div>
		<div class="switch-mode" v-if="servers_list.state == 0">
			{{ Translate("name_servers_sidebar") }}
		</div>
	</section>
</template>