<script>
import AddCredit from "./add-credit.vue";
import Settings from "./settings.vue";
import Toast from "../toast.vue";

export default {
    data() {
        return {
            lang: {
                iso: this.$store.state.lang,
                translation: this.$translation,
            },
            add_credit: {
                state: 0,
            },
            open_settings: {
                state: 0,
            },
            subscribe: null,
        };
    },
    components: {
        AddCredit,
        Settings,
        Toast,
    },
    methods: {
        add_credit_state(state) {
            var ctx = this;
            ctx.add_credit.state = state;
        },
        open_settings_state(state) {
            this.open_settings.state = state;
        },
        Translate(name) {
            var ctx = this;
            if (ctx.lang.translation[ctx.lang.iso][name])
                return ctx.lang.translation[ctx.lang.iso][name];
            else return "None";
        },
        logout() {
            localStorage.removeItem("token");
            location.href = location.origin;
        },
    },
	mounted() {
		var ctx = this;

		ctx.subscribe = ctx.$store.subscribe((mutation) => {
            if (mutation.type == "lang") {
				ctx.lang.iso = mutation.payload;
			}
		});
	},
	beforeUnmount() {
		this.subscribe();
	},
};
</script>

<style>
@import url("../../assets/scss/navbar/navbar.css");
</style>

<template>
    <section class="navbar">
        <Toast></Toast>
        <AddCredit
            @add_credit="add_credit_state"
            :state="add_credit.state"
        ></AddCredit>
        <Settings
            @settings_state="open_settings_state"
            :state="open_settings.state"
        ></Settings>
        <div class="hud">
            <div class="avatar">
                <img
                    src="https://cdn.discordapp.com/avatars/623598087688028190/a_dc7f5aa631857c9cd80a341545aebf9e"
                />
            </div>
            <div class="info">
                <div class="username">AlexM</div>
                <div class="solde">{{Translate("solde")}} : 50€</div>
            </div>
        </div>
        <div class="box">
            <div class="navigation">
                <router-link to="/" :class="{ item: 1, active: $route.name == 'Home' }">
                    <span>{{ Translate("home") }}</span>
                </router-link>
                <router-link to="/shop" :class="{ item: 1, active: $route.name == 'shop' }">
                    <span>{{ Translate("store") }}</span>
                </router-link>
                <a
                    href="javascript:void(1)"
                    :class="{ item: 1 }"
                    v-on:click="add_credit.state = 1"
                >
                    <span>{{ Translate("add_solde") }}</span>
                </a>
                <router-link to="/faq" :class="{ item: 1, active: $route.name == 'faq' }">
                    <span>{{ Translate("faq") }}</span>
                </router-link>
                <a
                    href="javascript:void(1)"
                    :class="{ item: 1 }"
                    v-on:click="open_settings.state = 1"
                >
                    <span>{{ Translate("settings") }}</span>
                </a>
                <a
                    href="javascript:void(1)"
                    target="_newblank"
                    :class="{ item: 1 }"
                    v-on:click="logout"
                >
                    <span>{{ Translate("logout") }}</span>
                </a>
            </div>
        </div>
    </section>
</template>